import React, { useState, useEffect } from 'react';

import fetchJsonp from 'fetch-jsonp';

import Layout from '../../components/Layout';

import SmartFeedJobAlerts from '../../components/SmartFeedJobAlerts';

import { useCookies } from 'react-cookie';
import {
  trackAnalyticsEvent,
  trackAnalyticsPageView,
  useStateWithSessionStorage,
} from '../../components/Analytics';
import { useTracking } from 'react-tracking';

require('es6-promise').polyfill();

const JobAlertsPage = ({
  pageContext: { config },
  location,
  contentDataData,
  smartfeedID,
}) => {
  const siteConfig = config;

  const apiKey = smartfeedID || siteConfig.apiKey || siteConfig.smartfeedID;
  const [gdprKey, setGdprKey] = useState(
    smartfeedID ||
      siteConfig.gdprKey ||
      siteConfig.apiKey ||
      siteConfig.smartfeedID
  );
  const groupOrIdParam = 'group';
  const [smartfeedCompanyData, setSmartfeedCompanyData] = useState({});
  const [appGatewayURL, setAppGatewayURL] = useState({});
  const [vacanciesRouteLoading, setVacanciesRouteLoading] = useState(true);
  const [vacanciesRouteError, setVacanciesRouteError] = useState(null);

  const [cookies, setCookie] = useCookies(['ap-signals-user-id']);
  const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
    'ap-signals-session-id'
  );

  const { trackEvent } = useTracking(
    { page: 'JobAlertsPage', location: location, apiKey: siteConfig.apiKey },
    {
      dispatch: (data) =>
        trackAnalyticsEvent(
          data,
          cookies['ap-signals-user-id'],
          sessionIDValue,
          siteConfig
        ),
    }
  );

  useEffect(() => {
    trackEvent({ eventAction: 'initalised' });

    trackAnalyticsPageView(
      location,
      cookies['ap-signals-user-id'],
      sessionIDValue,
      siteConfig
    );
  }, [cookies, location, sessionIDValue, siteConfig, trackEvent]);

  useEffect(() => {
    if (apiKey) {
      let companyDetailsEndpoint = 'GetCompanyDetails';

      if (groupOrIdParam === 'group') {
        companyDetailsEndpoint = 'GetGroupDetails';
      }

      try {
        fetchJsonp(
          siteConfig.sfAPIEndpoint +
            '/CareerPage/' +
            companyDetailsEndpoint +
            '?id=' +
            apiKey,
          {
            timeout: 15000,
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonList) {
            fetchJsonp(
              siteConfig.sfAPIEndpoint +
                '/CareerPage/' +
                'GetApplicantGetwayURL' +
                '?companyId' +
                '=' +
                gdprKey,
              {
                timeout: 15000,
              }
            )
              .then(function (response) {
                return response.json();
              })
              .then(function (appgatway) {
                setVacanciesRouteLoading(false);
                if (
                  jsonList &&
                  jsonList.CompanyDetails &&
                  jsonList.CompanyDetails.length > 0
                ) {
                  setSmartfeedCompanyData(jsonList.CompanyDetails[0]);
                } else {
                  setSmartfeedCompanyData(jsonList);
                }
                setAppGatewayURL(appgatway);
              })
              .catch(function (ex) {
                setVacanciesRouteLoading(false);
                setVacanciesRouteError(ex);
                console.error(ex, 'error');
              });
          })
          .catch(function (ex) {
            setVacanciesRouteLoading(false);
            setVacanciesRouteError(ex);
            console.error(ex, 'error');
          });
      } catch (ex) {
        setVacanciesRouteLoading(false);
        setVacanciesRouteError(ex);
        console.error(ex, 'error');
      }
    } else {
      setVacanciesRouteLoading(false);
      setVacanciesRouteError('No API key was provided.');
    }
  }, [apiKey, groupOrIdParam, siteConfig.sfAPIEndpoint]);

  return (
    <Layout
      siteConfig={siteConfig}
      location={location}
      apiKey={apiKey}
      jobAlertsPage={true}
      groupOrIdParam={groupOrIdParam}
      smartfeedCompanyData={smartfeedCompanyData}
      appGatewayURL={appGatewayURL}
      vacanciesRouteLoading={vacanciesRouteLoading}
      vacanciesRouteError={vacanciesRouteError}
      title="Subscribe to Job Alerts"
      description="Get notified about our latest job postings"
    >
      {!smartfeedCompanyData ? (
        <>
          <div className="page__header-container page__header-container--job-alerts-landing">
            <div className="container">
              <div
                className="row justify-content-center page__header page__header--landing job-alerts-landing__header page__header--job-alerts-landing"
                id="listing-top"
              >
                <div className="col-12 col-lg-8 job-alerts-title-wrap">
                  <p>
                    Please wait while we load the job alerts config, if this
                    takes a while you may have the wrong URL.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : smartfeedCompanyData &&
        !vacanciesRouteLoading &&
        !vacanciesRouteError ? (
        <>
          <div className="page__header-container page__header-container--job-alerts-landing">
            <div className="container">
              <div
                className="row justify-content-center page__header page__header--landing job-alerts-landing__header page__header--job-alerts-landing"
                id="listing-top"
              >
                <div className="col-12 col-lg-8 job-alerts-title-wrap">
                  <h1>Subscribe to Job Alerts</h1>
                </div>
              </div>
            </div>
          </div>

          <div className="jobalerts-landing">
            <div className="container">
              <div className="row clearfix">
                <div className="col-md-12 column">
                  <div className="smartfeed-spacing">
                    {smartfeedCompanyData.JobAlertCompanySettings ||
                    siteConfig.JobAlertCompanySettings ? (
                      <div className="col-12 col-lg-8 job-alerts-panel">
                        <SmartFeedJobAlerts
                          siteConfig={siteConfig}
                          setVacancyLoading={false}
                          apiKey={siteConfig.apiKey || apiKey}
                          group={siteConfig.group || false}
                          gdprKey={
                            siteConfig.gdprKey || siteConfig.apiKey || apiKey
                          }
                          JobAlertCompanySettings={
                            siteConfig.JobAlertCompanySettings ||
                            smartfeedCompanyData.JobAlertCompanySettings
                          }
                          CompanyGroupName={
                            siteConfig.companyName ||
                            smartfeedCompanyData.CompanyName
                          }
                          OptInLink={
                            `${siteConfig.careersSiteDomain}/job-alerts/optin/?OptInID=` ||
                            `${siteConfig.careersSiteDomain}/${apiKey}/job-alerts/optin/?OptInID=`
                          }
                          UnsubscribeLink={
                            `${siteConfig.careersSiteDomain}/job-alerts/unsubscribe/?UnsubscribeID=` ||
                            `${siteConfig.careersSiteDomain}/${apiKey}/job-alerts/unsubscribe/?UnsubscribeID=`
                          }
                          VacancyPageBaseURL={
                            `${siteConfig.careersSiteDomain}/vacancies/vacancy/` ||
                            `${siteConfig.careersSiteDomain}/${apiKey}/vacancies/vacancy/`
                          }
                          ReturnToSearchURL={
                            `${siteConfig.careersSiteDomain}/vacancies/` ||
                            `${siteConfig.careersSiteDomain}/${apiKey}/vacancies/`
                          }
                          contentDataData={contentDataData || {}}
                          location={siteConfig.showLocationJA}
                          region={siteConfig.showRegionJA}
                          category={siteConfig.showCategoryJA}
                          industry={siteConfig.showIndustryJA}
                          jobType={siteConfig.showJobTypeJA}
                          jobTime={siteConfig.showJobTimeJA}
                          trackEvent={trackEvent}
                        />
                      </div>
                    ) : (
                      <p>
                        Sorry, we could not load the job alerts settings
                        required for this form.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="page__header-container page__header-container--job-alerts-landing">
            <div className="container">
              <div
                className="row justify-content-center page__header page__header--landing job-alerts-landing__header page__header--job-alerts-landing"
                id="listing-top"
              >
                <div className="col-12 col-lg-8 job-alerts-title-wrap">
                  <p>Please wait while we load the job alerts optin form</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default JobAlertsPage;
